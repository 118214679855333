import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import { get as getPath } from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { InputError } from '../InputError/InputError';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

import { SearchDropdown } from 'components/SearchDropdown/SearchDropdown';

import { TestSubTypesField } from 'containers/DeviceForm/components/TestSubTypes';
import { TestTypesField } from 'containers/DeviceForm/components/TestTypes';
import { emailRegex } from 'services/config';
import moment from 'moment';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { getInputTypeFromField } from '../../utils/input-helpers';
import NumberRange from './Fields/NumberRange';
import DateRangePickerField from './Fields/DateRangePicker';
import TimeRangePicker from './Fields/TimeRangePicker';
import DateTimeRangePickerField from './Fields/DateTimeRangePicker';
import DateTimePickerField from './Fields/DateTimePicker';
import TimePickerField from './Fields/TimePicker';
import { CloudQueue } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import zIndex from '@material-ui/core/styles/zIndex';

interface InputOptions {
  multiline: any;
  required: any;
  label: any;
  disabledOn: any;
  disabledOnEdit: boolean;
  defaultDate: any;
  defaultFormat: string;
  accordionFields: any;
  type: string;
  span: any;
  fieldName: string;
  options: any;
  pattern: any;
  minLength: any;
  maxLength: any;
  error: any;
  subType?: string;
  disabled?: boolean;
  auxiliaryLabel?: string;
  defaultValue?: string;
  onUpdate?: any;
  index?: any;
  customFilter?: (option: Object, input: string) => boolean;
  customStyle?: object;
  accessor?: any;
  optionsCustomStyle?: false;
}

const setSelectedLabel = (value, optionsData) => {
  var foundLabel;
  if (value) {
    foundLabel = optionsData.find((data) => {
      return data.value === value;
    });
  }
  return foundLabel;
};

const setDefaultData = (matcherId, options) => {
  if (matcherId && matcherId?.length && matcherId[0]?._id) {
    matcherId = matcherId[0]._id;
  }
  const foundResult = options.find((option) => {
    return (
      option.value === matcherId ||
      (option._id && option._id === matcherId) ||
      option.value === matcherId?._id
    );
  });
  return foundResult || null;
};

const setDefaultValue = ({ value, options }) => {
  const defaultValue = options.find(
    (option) => option.value === value || option.value === value?._id,
  );
  if (defaultValue) {
    return defaultValue.value;
  } else {
    return '';
  }
};

export const getFieldFromType = (
  form,
  fieldData: InputOptions,
  index,
  formData,
  t: any = (string) => 'Missing translation',
  editMode = false,
  disabled = false,
  onUpdate = (fieldName, value) => {},
  setValue: any = false,
  salesforceId: string = '',
) => {
  const customStyles = {
    option: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      border: 0,
      borderBottom: '2px solid #e0e0e0',
      borderColor: 'white',
      boxShadow: '0',
      '&:hover': {
        borderBottom: '2px solid #e0e0e0',
      },
      padding: '0px 0px',
      margin: '0px 0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 0px',
    }),
    menu: (provided) => ({
      ...provided,
      ...(fieldData.customStyle || {}),
    }),
  };
  /***
   * CUSTOM LOGIC FOR DATEPICKER
   * Because we are passing dates in format DD-MM-YYYY from the table,
   * if the date is already in ISO format, do nothing, else format the date
   *
   * Will require a bit of attention when react-table is implemented
   */

  if (
    fieldData.type === 'project-datepicker' &&
    !moment(formData[fieldData.fieldName], moment.ISO_8601).isValid() &&
    formData[fieldData.fieldName]
  ) {
    formData[fieldData.fieldName] = moment
      .utc(formData[fieldData.fieldName], 'DD-MM-YYYY')
      .toDate();
  }

  if (fieldData.fieldName === 'group') {
    const fieldDataName = formData[fieldData.fieldName]?.name;

    if (!fieldData.options.length) {
      fieldData.options = [
        { value: fieldDataName, label: fieldDataName },
        { value: fieldDataName, label: fieldDataName },
      ];
    }
  }
  switch (fieldData.type) {
    case 'text':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
                minLength: {
                  value: fieldData.minLength,
                  message: t('common:minLength', {
                    minLength: fieldData.minLength,
                  }),
                },
                maxLength: fieldData.maxLength,
                pattern: {
                  value: fieldData.pattern,
                  message: t('common:invalidPattern', {
                    fieldName: t(fieldData.label),
                  }),
                },
              });
              return (
                <TextField
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                    defaultValue: formData && formData[fieldData.fieldName],
                    maxLength: fieldData.maxLength,
                    minLength: fieldData.minLength,
                    ...(fieldData.subType &&
                      getInputTypeFromField(fieldData.subType)),
                  }}
                  inputRef={ref}
                  disabled={
                    fieldData.disabledOnEdit || fieldData.disabled || false
                  }
                  multiline={!!fieldData.multiline}
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  onChange={(event) => {
                    form.setValue(fieldData.fieldName, event.target.value);
                    onChange((value = event.target.value));
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors[fieldData.fieldName] && (
                      <InputError
                        message={
                          form.formState.errors[fieldData.fieldName].message ||
                          t(fieldData.error)
                        }
                      />
                    )
                  }
                />
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={
              formData && formData[fieldData.fieldName]
                ? formData[fieldData.fieldName]
                : t(fieldData.defaultValue)
            }
          />
        </Grid>
      );

    case 'number':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
                minLength: {
                  value: fieldData.minLength,
                  message: t('common:minLength', {
                    minLength: fieldData.minLength,
                  }),
                },
                maxLength: fieldData.maxLength,
                pattern: {
                  value: fieldData.pattern,
                  message: t('common:invalidPattern', {
                    fieldName: t(fieldData.label),
                  }),
                },
              });
              return (
                <TextField
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                    defaultValue: formData && formData[fieldData.fieldName],
                    maxLength: fieldData.maxLength,
                    minLength: fieldData.minLength,
                    ...(fieldData.subType &&
                      getInputTypeFromField(fieldData.subType)),
                  }}
                  type="number"
                  inputRef={ref}
                  disabled={
                    fieldData.disabledOnEdit || fieldData.disabled || false
                  }
                  multiline={!!fieldData.multiline}
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  onChange={(event) => {
                    form.setValue(fieldData.fieldName, event.target.value);
                    onChange((value = event.target.value));
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors[fieldData.fieldName] && (
                      <InputError
                        message={
                          form.formState.errors[fieldData.fieldName].message ||
                          t(fieldData.error)
                        }
                      />
                    )
                  }
                />
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={formData && formData[fieldData.fieldName]}
          />
        </Grid>
      );

    case 'information':
      const watchedField = form.watch(fieldData.disabledOn);
      return (
        (!watchedField || !fieldData.disabledOn) && (
          <Grid item xs={fieldData.span} key="informaton">
            <Grid
              container
              alignItems="center"
              justify="center"
              className="information"
            >
              <Grid item xs={1}>
                <InfoIcon className="infoIcon" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5">{t(fieldData.label)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      );
    case 'email':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
              });

              return (
                <TextField
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                    defaultValue: formData && formData[fieldData.fieldName],
                    maxLength: fieldData.maxLength,
                  }}
                  inputRef={ref}
                  multiline={!!fieldData.multiline}
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  onChange={(event) => {
                    form.setValue(fieldData.fieldName, event.target.value);
                    onChange((value = event.target.value));
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors[fieldData.fieldName] && (
                      <InputError message={t(fieldData.error)} />
                    )
                  }
                />
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
              pattern: fieldData.pattern || emailRegex,
            }}
            defaultValue={formData && formData[fieldData.fieldName]}
          />
        </Grid>
      );
    case 'select':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  select
                  disabled={
                    (editMode && fieldData.disabledOnEdit) ||
                    fieldData.disabled ||
                    false
                  }
                  defaultValue={
                    formData &&
                    setDefaultValue({
                      value: formData[fieldData.fieldName],
                      options: fieldData.options,
                    })
                  }
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  error={form.formState.errors[fieldData.fieldName]}
                  helperText={
                    form.formState.errors[fieldData.fieldName] && (
                      <InputError message={t(fieldData.error)} />
                    )
                  }
                  SelectProps={{
                    inputProps: {
                      value: value,
                    },
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  onChange={(e) => {
                    onChange((value = e.target.value));
                    onUpdate(fieldData.fieldName, e.target.value);
                  }}
                >
                  {fieldData.options &&
                    fieldData?.options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                </TextField>
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            defaultValue={
              fieldData?.defaultValue
                ? fieldData.defaultValue
                : formData &&
                  setDefaultValue({
                    value: formData[fieldData.fieldName],
                    options: fieldData.options,
                  })
            }
            rules={{
              required: fieldData.required,
            }}
          />
        </Grid>
      );

    case 'specification-select':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
              });
              return editMode ? (
                <TextField
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                    defaultValue:
                      formData && getPath(formData, fieldData.fieldName),
                  }}
                  select
                  inputRef={ref}
                  disabled={
                    fieldData.disabledOnEdit || fieldData.disabled || false
                  }
                  multiline={!!fieldData.multiline}
                  onChange={(event) => {
                    form.setValue(fieldData.fieldName, event.target.value);
                    onUpdate(fieldData.fieldName, event.target.value);
                    onChange((value = event.target.value));
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                >
                  {fieldData.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled || false}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Typography variant={'body1'}>
                  {
                    fieldData.options.find(
                      (item) =>
                        item.value === getPath(formData, fieldData.fieldName),
                    )?.label
                  }
                </Typography>
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={formData && getPath(formData, fieldData.fieldName)}
          />
        </Grid>
      );

    case 'select-testType':
      return (
        <TestTypesField
          form={form}
          field={fieldData}
          formData={formData}
          t={t}
        />
      );

    case 'select-multiple-subTypes':
      return (
        <TestSubTypesField
          form={form}
          field={fieldData}
          formData={formData}
          t={t}
        />
      );
    case 'text-contact':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(
                'contact.' + fieldData.fieldName,
                {
                  required: fieldData.required,
                },
              );

              return (
                <TextField
                  inputProps={{
                    ...rest,
                    name: 'contact.' + fieldData.fieldName,
                    defaultValue:
                      formData && formData.contact
                        ? formData.contact[fieldData.fieldName]
                        : '',
                    maxLength: fieldData.maxLength,
                  }}
                  inputRef={ref}
                  multiline={!!fieldData.multiline}
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  onChange={(event) => {
                    form.setValue(
                      'contact.' + fieldData.fieldName,
                      event.target.value,
                    );
                    onChange((value = event.target.value));
                    if (event?.target?.value === '') {
                      form.setError('contact.' + fieldData.fieldName, {
                        type: 'manual',
                      });
                    }
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors.contact?.[fieldData?.fieldName] && (
                      <InputError message={t(fieldData.error)} />
                    )
                  }
                />
              );
            }}
            name={'contact.' + fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={
              formData && formData.contact
                ? formData.contact[fieldData.fieldName]
                : ''
            }
          />
        </Grid>
      );
    case 'email-contact':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(
                'contact.' + fieldData.fieldName,
                {
                  required: fieldData.required,
                },
              );
              return (
                <TextField
                  inputProps={{
                    ...rest,
                    name: 'contact.' + fieldData.fieldName,
                    defaultValue:
                      formData && formData.contact
                        ? formData.contact[fieldData.fieldName]
                        : '',
                    maxLength: fieldData.maxLength,
                  }}
                  inputRef={ref}
                  multiline={!!fieldData.multiline}
                  label={
                    fieldData.required
                      ? `${t(fieldData.label)} *`
                      : t(fieldData.label)
                  }
                  onChange={(event) => {
                    form.setValue(
                      'contact.' + fieldData.fieldName,
                      event.target.value,
                    );
                    onChange((value = event.target.value));
                    if (event?.target?.value === '') {
                      form.setError('contact.' + fieldData.fieldName, {
                        type: 'manual',
                      });
                    }
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors.contact?.[fieldData?.fieldName] && (
                      <InputError message={t(fieldData.error)} />
                    )
                  }
                />
              );
            }}
            name={'contact.' + fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
              pattern: emailRegex,
            }}
            defaultValue={
              formData && formData.contact
                ? formData.contact[fieldData.fieldName]
                : ''
            }
          />
        </Grid>
      );
    case 'select-contact':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextField
                label={
                  fieldData.required
                    ? `${t(fieldData.label)} *`
                    : t(fieldData.label)
                }
                select
                defaultValue={
                  formData &&
                  setDefaultValue({
                    value:
                      formData && formData.contact
                        ? formData.contact[fieldData.fieldName]
                        : '',
                    options: fieldData.options,
                  })
                }
                margin={'dense'}
                fullWidth
                variant="standard"
                error={form.formState.errors[fieldData.fieldName]}
                helperText={
                  form.formState.errors.contact?.[fieldData?.fieldName] && (
                    <InputError message={t(fieldData.error)} />
                  )
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                onChange={(e) => onChange((value = e.target.value))}
              >
                {fieldData.options &&
                  fieldData?.options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {t(option.label)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            name={'contact.' + fieldData.fieldName}
            control={form.control}
            defaultValue={
              formData &&
              setDefaultValue({
                value:
                  formData && formData.contact
                    ? formData.contact[fieldData.fieldName]
                    : '',
                options: fieldData.options,
              })
            }
            rules={{
              required: fieldData.required,
            }}
          />
        </Grid>
      );
    case 'search-select':
      return (
        (!fieldData.disabledOn ||
          ['none'].includes(form.watch(fieldData.disabledOn)) ||
          (form.watch(fieldData.disabledOn, 'none') === 'none' &&
            formData[fieldData.disabledOn] === 'none')) && (
          <Grid item xs={fieldData.span} key={`${index}-form-field`}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <div>
                  {value !== null ? (
                    <FormLabel component="legend" filled={true} focused={true}>
                      {t(fieldData.label)} {fieldData.required && '*'}
                    </FormLabel>
                  ) : null}
                  <ReactSelect
                    options={fieldData.options}
                    onChange={(e) => {
                      form.setValue(fieldData.fieldName, e.value);
                      onChange((value = e?.value));
                      onUpdate(fieldData.fieldName, value);
                    }}
                    isDisabled={
                      (fieldData.disabledOnEdit && editMode) ||
                      fieldData.disabled
                    }
                    styles={customStyles}
                    placeholder={
                      fieldData.auxiliaryLabel
                        ? t(fieldData.auxiliaryLabel, { ns: 'projectPlans' })
                        : t(fieldData.label) +
                          (' ' + fieldData.required ? '*' : '')
                    }
                    defaultValue={setSelectedLabel(value, fieldData.options)}
                  />
                  {form.formState.errors[fieldData.fieldName] && (
                    <InputError message={t(fieldData.error)} />
                  )}
                </div>
              )}
              name={fieldData.fieldName}
              defaultValue={
                (formData &&
                  setDefaultData(
                    form.getValues(fieldData.fieldName) ||
                      formData[fieldData.fieldName],
                    fieldData.options,
                  )?.value) ||
                null
              }
              control={form.control}
              rules={{ required: fieldData.required }}
            />
          </Grid>
        )
      );
    case 'search-select-custom':
      return (
        (!fieldData.disabledOn ||
          ['none'].includes(form.watch(fieldData.disabledOn)) ||
          (form.watch(fieldData.disabledOn, 'none') === 'none' &&
            formData[fieldData.disabledOn] === 'none')) && (
          <Grid item xs={fieldData.span} key={`${index}-form-field`}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <div>
                  {value !== null ? (
                    <FormLabel component="legend" filled={true} focused={true}>
                      {t(fieldData.label)} {fieldData.required && '*'}
                    </FormLabel>
                  ) : null}
                  <ReactSelect
                    options={fieldData.options}
                    filterOption={fieldData.customFilter}
                    onChange={(e) => {
                      form.setValue(fieldData.fieldName, e.value);
                      onChange((value = e?.value));
                      onUpdate(fieldData.fieldName, value);
                    }}
                    isDisabled={
                      (fieldData.disabledOnEdit && editMode) ||
                      fieldData.disabled
                    }
                    styles={customStyles}
                    placeholder={
                      fieldData.defaultValue
                        ? fieldData.options.find(
                            (option) => option.value === fieldData.defaultValue,
                          )?.label
                        : t(fieldData.auxiliaryLabel, { ns: 'projectPlans' })
                    }
                    defaultValue={
                      fieldData.defaultValue
                        ? fieldData.options.find(
                            (option) => option.value === fieldData.defaultValue,
                          )
                        : setSelectedLabel(value, fieldData.options)
                    }
                  />
                  {form.formState.errors[fieldData.fieldName] && (
                    <InputError message={t(fieldData.error)} />
                  )}
                </div>
              )}
              name={fieldData.fieldName}
              defaultValue={
                (formData &&
                  setDefaultData(
                    form.getValues(fieldData.fieldName) ||
                      formData[fieldData.fieldName],
                    fieldData.options,
                  )?.value) ||
                null
              }
              control={form.control}
              rules={{ required: fieldData.required }}
            />
          </Grid>
        )
      );
    case 'materials-number-search-select':
      const tempNumberValue = form.watch(fieldData.fieldName);
      return (
        (!fieldData.disabledOn ||
          ['none'].includes(form.watch(fieldData.disabledOn)) ||
          (form.watch(fieldData.disabledOn, 'none') === 'none' &&
            formData[fieldData.disabledOn] === 'none')) && (
          <Grid item xs={fieldData.span} key={`${index}-form-field`}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <div>
                  {value !== null ? (
                    <FormLabel component="legend" filled={true} focused={true}>
                      {fieldData.label}
                    </FormLabel>
                  ) : null}
                  {editMode ? (
                    <ReactSelect
                      value={{
                        label: tempNumberValue,
                        value: tempNumberValue,
                      }}
                      options={fieldData.options}
                      onChange={(e) => {
                        form.setValue(fieldData.fieldName, e.value);
                        onChange((value = e?.value));
                        onUpdate(
                          fieldData.accessor || fieldData.fieldName,
                          e.value,
                        );
                      }}
                      isDisabled={
                        (fieldData.disabledOnEdit && editMode) ||
                        fieldData.disabled
                      }
                      styles={
                        fieldData.optionsCustomStyle
                          ? {
                              ...customStyles,
                              menuList: (provided) => ({
                                ...provided,
                                maxHeight: 100,
                                overflowY: 'auto',
                              }),
                            }
                          : customStyles
                      }
                      placeholder={
                        fieldData.auxiliaryLabel
                          ? fieldData.auxiliaryLabel
                          : fieldData.label +
                            (' ' + fieldData.required ? '*' : '')
                      }
                      defaultValue={
                        formData && getPath(formData, fieldData.fieldName)
                      }
                    />
                  ) : (
                    <Typography variant={'body1'}>
                      {formData && getPath(formData, fieldData.fieldName)}
                    </Typography>
                  )}
                  {form.formState.errors[fieldData.fieldName] && (
                    <InputError message={fieldData.error} />
                  )}
                </div>
              )}
              name={fieldData.fieldName}
              defaultValue={formData && getPath(formData, fieldData.fieldName)}
              control={form.control}
              rules={{ required: fieldData.required }}
            />
          </Grid>
        )
      );
    case 'materials-name-search-select':
      const tempNameValue = form.watch(fieldData.fieldName);
      return (
        (!fieldData.disabledOn ||
          ['none'].includes(form.watch(fieldData.disabledOn)) ||
          (form.watch(fieldData.disabledOn, 'none') === 'none' &&
            formData[fieldData.disabledOn] === 'none')) && (
          <Grid item xs={fieldData.span} key={`${index}-form-field`}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <div>
                  {value !== null ? (
                    <FormLabel component="legend" filled={true} focused={true}>
                      {fieldData.label} {fieldData.required && '*'}
                    </FormLabel>
                  ) : null}
                  {editMode ? (
                    <ReactSelect
                      value={{
                        label: tempNameValue,
                        value: tempNameValue,
                      }}
                      options={fieldData.options}
                      onChange={(e) => {
                        form.setValue(fieldData.fieldName, e.value);
                        onChange((value = e?.value));
                        onUpdate(fieldData.fieldName, e.value);
                      }}
                      isDisabled={
                        (fieldData.disabledOnEdit && editMode) ||
                        fieldData.disabled
                      }
                      styles={customStyles}
                      placeholder={
                        fieldData.auxiliaryLabel
                          ? fieldData.auxiliaryLabel
                          : fieldData.label +
                            (' ' + fieldData.required ? '*' : '')
                      }
                      defaultValue={
                        formData && getPath(formData, fieldData.fieldName)
                      }
                    />
                  ) : (
                    <Typography variant={'body1'}>
                      {formData && getPath(formData, fieldData.fieldName)}
                    </Typography>
                  )}
                  {form.formState.errors[fieldData.fieldName] && (
                    <InputError message={fieldData.error} />
                  )}
                </div>
              )}
              name={fieldData.fieldName}
              defaultValue={formData && getPath(formData, fieldData.fieldName)}
              control={form.control}
              rules={{ required: fieldData.required }}
            />
          </Grid>
        )
      );
    case 'password':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
              });

              return (
                <TextField
                  inputRef={ref}
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                  }}
                  label={
                    fieldData.required
                      ? `${fieldData.label} *`
                      : fieldData.label
                  }
                  name={fieldData.fieldName}
                  type="password"
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                  helperText={
                    form.formState.errors[fieldData.fieldName] && (
                      <InputError message={fieldData.error} />
                    )
                  }
                  onChange={(e) => onChange((value = e.target.value))}
                />
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={''}
          />
        </Grid>
      );
    case 'search-select-button':
      return (
        <SearchDropdown
          key={`${index}-search-dropdown`}
          form={form}
          field={fieldData}
          styles={customStyles}
          formData={formData}
          disabled={disabled}
          t={t}
        />
      );
    case 'accordion':
      return (
        <div key={`${index}-form-field`} className={'accordion-holder'}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h4'}>{t(fieldData.label)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {Object?.entries(fieldData.accordionFields)?.map(
                  (accordionField: [string, any]) => {
                    let fieldNestings = fieldData.fieldName.split('.');
                    return (
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Grid
                            item
                            container
                            xs={12}
                            alignItems={'center'}
                            className={'expanded-accordion'}
                          >
                            <Checkbox
                              onChange={(e) => onChange(e.target.checked)}
                              checked={value}
                            />
                            <Typography variant={'body1'}>
                              {t(accordionField[1].label)}
                            </Typography>
                          </Grid>
                        )}
                        name={fieldData.fieldName + '.' + accordionField[0]}
                        control={form.control}
                        defaultValue={
                          formData && fieldNestings.length
                            ? formData[fieldNestings[0]]?.[fieldNestings[1]]?.[
                                accordionField[0]
                              ]
                            : false
                        }
                      />
                    );
                  },
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    case 'project-text':
      return (
        <Grid
          item
          container
          xs={fieldData.span || 12}
          key={`${index}-form-field`}
          justify="space-between"
          alignItems="center"
          className={!editMode ? 'disabled-editing' : ''}
        >
          <Grid item xs={5}>
            <Typography variant="body2">
              {t(fieldData.label)} {fieldData.required && '*'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Controller
              render={({ field: { onChange, value } }) => {
                const { ref, ...rest } = form.register(fieldData.fieldName, {
                  required: fieldData.required,
                });

                return editMode ? (
                  <TextField
                    inputProps={{
                      ...rest,
                      name: fieldData.fieldName,
                      defaultValue: formData && formData[fieldData.fieldName],
                      maxLength: fieldData.maxLength,
                    }}
                    inputRef={ref}
                    placeholder={t(fieldData.label)}
                    onChange={(event) => {
                      form.setValue(fieldData.fieldName, event.target.value);
                      onChange((value = event.target.value));
                    }}
                    onBlur={(event) => {
                      form.setValue(
                        fieldData.fieldName,
                        event.target.value.trim(),
                      );
                    }}
                    margin={'dense'}
                    fullWidth
                    multiline={!!fieldData.multiline}
                    rowsMax={5}
                    variant="standard"
                    helperText={
                      form.formState.errors[fieldData.fieldName] && (
                        <InputError message={fieldData.error} />
                      )
                    }
                  />
                ) : (
                  <Typography variant={'body1'} className={'underlined'}>
                      {fieldData.defaultValue
                          ? t(fieldData.defaultValue)
                          : formData[fieldData.fieldName]}
                    {form.formState.errors[fieldData.fieldName] && (
                      <InputError message={t(fieldData.error)} />
                    )}
                  </Typography>
                );
              }}
              name={fieldData.fieldName}
              control={form.control}
              rules={{
                required: fieldData.required,
              }}
              defaultValue={formData && formData[fieldData.fieldName]}
            />
          </Grid>
        </Grid>
      );
    case 'datepicker':
    case 'search-datepicker':
      let defaultValue;
      const searchDatePickerWF = form.watch(fieldData.disabledOn);

      if (
        ['dateOfDeactivation', 'dateOfDeletion'].includes(fieldData.fieldName)
      ) {
        defaultValue = searchDatePickerWF;
        if (defaultValue === undefined) {
          defaultValue = formData.sandbox;
        }
      }

      return (
        (defaultValue || !fieldData.disabledOn) && (
          <Grid
            item
            container
            xs={fieldData.span || 12}
            key={`${index}-form-field`}
            justify="space-between"
            alignItems="center"
            className={!editMode ? 'disabled-editing' : ''}
          >
            <Grid item xs={12} className={'search-datepicker'}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format={'dd/MM/yyyy'}
                      margin="normal"
                      fullWidth
                      readOnly={fieldData.disabled}
                      id="date-picker-inline"
                      label={
                        !value && (fieldData.label || 'Please select a date')
                      }
                      autoOk={true}
                      value={
                        form.getValues(fieldData.fieldName) ||
                        (formData && formData[fieldData.fieldName]) ||
                        fieldData.defaultDate ||
                        null
                      }
                      onChange={(e) => {
                        const date = moment.utc(e);
                        e = date.toDate();
                        form.setValue(fieldData.fieldName, e);
                        onChange(e);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      inputVariant={'standard'}
                    />
                  </MuiPickersUtilsProvider>
                )}
                name={fieldData.fieldName}
                control={form.control}
                rules={{
                  required: fieldData.required,
                }}
                defaultValue={
                  form.getValues(fieldData.fieldName) ||
                  (formData && formData[fieldData.fieldName]) ||
                  fieldData.defaultDate ||
                  null
                }
              />
              {form.formState.errors[fieldData.fieldName] && (
                <InputError message={fieldData.error} />
              )}
            </Grid>
          </Grid>
        )
      );
    case 'project-datepicker':
      return (
        <Grid
          item
          container
          xs={fieldData.span || 12}
          key={`${index}-form-field`}
          justify="space-between"
          alignItems="center"
          className={!editMode ? 'disabled-editing' : ''}
        >
          <Grid item xs={5}>
            <Typography variant="body2">
              {t(fieldData.label)} {fieldData.required && '*'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Controller
              render={({ field: { onChange, value } }) =>
                editMode ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format={'dd/MM/yyyy'}
                      margin="normal"
                      fullWidth
                      id="date-picker-inline"
                      label={''}
                      autoOk={true}
                      value={
                        form.getValues(fieldData.fieldName) ||
                        (formData && formData[fieldData.fieldName]) ||
                        fieldData.defaultDate ||
                        null
                      }
                      onChange={(e) => {
                        const date = moment.utc(e);
                        e = date.toDate();
                        form.setValue(fieldData.fieldName, e);
                        onChange(e);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <Typography variant={'body1'}>
                    {formData[fieldData.fieldName] &&
                      moment
                        .utc(formData[fieldData.fieldName])
                        .local()
                        .format(fieldData.defaultFormat || 'DD/MM/yyyy')}
                  </Typography>
                )
              }
              name={fieldData.fieldName}
              control={form.control}
              rules={{
                required: fieldData.required,
              }}
              defaultValue={
                form.getValues(fieldData.fieldName) ||
                (formData && formData[fieldData.fieldName]) ||
                fieldData.defaultDate ||
                null
              }
            />
            {form.formState.errors[fieldData.fieldName] && (
              <InputError message={t(fieldData.error)} />
            )}
          </Grid>
        </Grid>
      );
    case 'project-search-select':
      return (
        <Grid
          item
          container
          xs={fieldData.span || 12}
          key={`${index}-form-field`}
          justify="space-between"
          alignItems="center"
          className={!editMode ? 'disabled-editing' : ''}
        >
          <Grid item xs={5}>
            <Typography variant="body2">
              {t(fieldData.label)} {fieldData.required && '*'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {fieldData.options && fieldData.options.length ? (
              <Controller
                render={({ field: { onChange, value } }) =>
                  editMode ? (
                    <ReactSelect
                      options={fieldData.options}
                      onChange={(e) => {
                        onChange(e?.value || null);
                        form.setValue(fieldData.fieldName, e?.value || null);
                      }}
                      isDisabled={fieldData.disabled}
                      isClearable
                      styles={customStyles}
                      placeholder={t(fieldData.label)}
                      defaultValue={
                        (fieldData.options.length === 1 &&
                          fieldData.options[0]) ||
                        (formData[fieldData.fieldName] &&
                          (typeof formData[fieldData.fieldName] === 'string'
                            ? {
                                label: formData[fieldData.fieldName],
                                value: formData[fieldData.fieldName],
                              }
                            : {
                                label: formData[fieldData.fieldName]?.name,
                                value: formData[fieldData.fieldName]?._id,
                              })) ||
                        (formData[fieldData.fieldName] &&
                          setDefaultData(
                            formData[fieldData.fieldName],
                            fieldData.options,
                          ))
                      }
                    />
                  ) : (
                    <Typography variant={'body1'}>
                      {formData[fieldData.fieldName]?.name ||
                        t(
                          setDefaultData(
                            formData[fieldData.fieldName],
                            fieldData.options,
                          )?.label,
                        )}
                    </Typography>
                  )
                }
                name={fieldData.fieldName}
                control={form.control}
                rules={{
                  required: fieldData.required,
                }}
                defaultValue={
                  (fieldData.options.length === 1 &&
                    fieldData.options[0].value) ||
                  (formData[fieldData.fieldName] &&
                    (formData[fieldData.fieldName]._id ||
                      setDefaultData(
                        formData[fieldData.fieldName],
                        fieldData.options,
                      )?.value))
                }
              />
            ) : (
              <ReactSelect
                options={[]}
                onChange={() => {}}
                isClearable
                styles={customStyles}
                placeholder={fieldData.label}
              />
            )}

            {form.formState.errors[fieldData.fieldName] && (
              <InputError message={t(fieldData.error)} />
            )}
          </Grid>
        </Grid>
      );
    case 'checkbox':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
              });

              return (
                <Grid container xs={12} alignItems={'center'}>
                  <Grid item xs={10}>
                    <Typography variant={'h5'}>{t(fieldData.label)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      size="medium"
                      disabled={fieldData.disabled || false}
                    />
                  </Grid>
                </Grid>
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={formData && formData[fieldData.fieldName]}
          />
        </Grid>
      );
    case 'checkbox-templates':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
              });

              return (
                <Grid container xs={12} alignItems={'center'}>
                  <Grid item>
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      size="medium"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant={'body1'}>{fieldData.label}</Typography>
                  </Grid>
                </Grid>
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={formData}
          />
        </Grid>
      );

    case 'range-text':
      return <NumberRange form={form} field={fieldData} formData={formData} />;
    case 'range-datepicker':
      return (
        <DateRangePickerField
          form={form}
          field={fieldData}
          formData={formData}
        />
      );
    case 'range-timepicker':
      return (
        <TimeRangePicker form={form} field={fieldData} formData={formData} />
      );

    case 'range-datetimepicker':
      return (
        <DateTimeRangePickerField
          form={form}
          field={fieldData}
          formData={formData}
        />
      );

    case 'datetimepicker':
      return (
        <DateTimePickerField
          form={form}
          field={fieldData}
          formData={formData}
        />
      );
    case 'timepicker':
      return (
        <TimePickerField form={form} field={fieldData} formData={formData} />
      );

    case 'switch':
      return (
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <Switch
                color="primary"
                checked={formData[fieldData.fieldName]}
                onChange={(event) => {
                  onChange(event.target.checked);
                }}
              />
            );
          }}
          name={fieldData.fieldName}
          control={form.control}
          rules={{
            required: fieldData.required,
          }}
          defaultValue={formData[fieldData.fieldName] || false}
        />
      );
    case 'table-text':
      return (
        <Grid item xs={fieldData.span} key={`${index}-form-field`}>
          <Controller
            render={({ field: { onChange, value } }) => {
              const { ref, ...rest } = form.register(fieldData.fieldName, {
                required: fieldData.required,
                minLength: {
                  value: fieldData.minLength,
                  message: `Minimum ${fieldData.minLength} characters required`,
                },
                maxLength: fieldData.maxLength,
                pattern: {
                  value: fieldData.pattern,
                  message: `Please enter a valid ${fieldData.label.toLowerCase()}`,
                },
              });
              return editMode ? (
                <TextField
                  inputProps={{
                    ...rest,
                    name: fieldData.fieldName,
                    defaultValue:
                      formData && getPath(formData, fieldData.fieldName),
                    maxLength: fieldData.maxLength,
                    minLength: fieldData.minLength,
                    ...(fieldData.subType &&
                      getInputTypeFromField(fieldData.subType)),
                  }}
                  inputRef={ref}
                  disabled={
                    fieldData.disabledOnEdit || fieldData.disabled || false
                  }
                  multiline={!!fieldData.multiline}
                  label={fieldData.label}
                  onChange={(event) => {
                    form.setValue(fieldData.fieldName, event.target.value);
                    onChange((value = event.target.value));
                    onUpdate(
                      fieldData.accessor || fieldData.fieldName,
                      event.target.value,
                    );
                  }}
                  margin={'dense'}
                  fullWidth
                  variant="standard"
                />
              ) : (
                <Typography variant={'body1'}>
                  {formData && getPath(formData, fieldData.fieldName)}
                </Typography>
              );
            }}
            name={fieldData.fieldName}
            control={form.control}
            rules={{
              required: fieldData.required,
            }}
            defaultValue={formData && getPath(formData, fieldData.fieldName)}
          />
        </Grid>
      );
    case 'salesforce-button':
      return salesforceId ? (
        <Grid
          item
          container
          xs={fieldData.span || 12}
          key={`${index}-form-field`}
          justify="space-between"
          alignItems="center"
          className={!editMode ? 'disabled-editing' : ''}
        >
          <Grid item xs={5}>
            <Typography variant="body2">
              {t(fieldData.label)} {fieldData.required && '*'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <IconButton
              component="a"
              href={`https://hesse.lightning.force.com/lightning/r/Task/${salesforceId}/view`}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              aria-label="link to salesforce task"
            >
              <Box display="flex" alignItems="center">
                <CloudQueue />
                <Typography variant={'body2'}>
                  {t('project:openSalesforceProject')}
                </Typography>
              </Box>
            </IconButton>
          </Grid>
        </Grid>
      ) : null;

    default:
      break;
  }
};
