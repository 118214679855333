import {
  MATERIAL_DETAILS_START,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_DETAILS_ERROR,
  MATERIAL_SPECIFICATION_ADD,
  MATERIAL_SPECIFICATION_REMOVE,
  MATERIAL_DETAILS_RESET,
  MATERIAL_BASE_TYPE_SUCCESS,
  MATERIAL_RECIPE_VARIANT_SUCCESS,
} from './MaterialDetailsTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    specificationRowMap: {},
    specifications: [],
    material: {},
    recipeVariants: [],
  },
  allBaseTypeMaterials: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case MATERIAL_DETAILS_START:
      return { ...state, loading: true, error: null };

    case MATERIAL_DETAILS_SUCCESS:
      const specificationRowMap = {};
      (action.payload?.specifications || []).map((spec) => {
        const generatedId = Date.now() + Math.floor(Math.random() * 1000);
        specificationRowMap[generatedId] = {
          ...spec,
          generatedId,
        };
      });

      return {
        ...state,
        data: {
          ...action.payload,
          specifications: Object.values(specificationRowMap),
          specificationRowMap,
        },
        loading: false,
      };

    case MATERIAL_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case MATERIAL_SPECIFICATION_ADD:
      return {
        ...state,
        data: {
          ...state.data,
          specifications: [...state.data.specifications, action.payload],
          specificationRowMap: {
            ...state.data.specificationRowMap,
            [action.payload.generatedId]: action.payload,
          },
        },
      };

    case MATERIAL_SPECIFICATION_REMOVE:
      const specificationRowMapEdited = { ...state.data.specificationRowMap };
      // delete specificationRowMapEdited[action.payload];
      specificationRowMapEdited[action.payload].deleted = true;
      return {
        ...state,
        data: {
          ...state.data,
          specification: (state?.data?.specifications || []).map((spec) =>
            spec.generatedId !== action.payload
              ? spec
              : { ...spec, deleted: true },
          ),
          specificationRowMap: specificationRowMapEdited,
        },
      };
    case MATERIAL_RECIPE_VARIANT_SUCCESS:
      let existingConfig = false;
      const recipeVariantConfigCopy = state.data.recipeVariants.map(
        (config) => {
          if (config._id === action.payload._id) {
            existingConfig = true;
            return {
              ...action.payload,
            };
          }
          return config;
        },
      );
      if (!existingConfig) {
        recipeVariantConfigCopy.unshift(action.payload);
      }
      return {
        ...state,
        data: {
          ...state.data,
          recipeVariants: recipeVariantConfigCopy,
        },
      };

    case MATERIAL_BASE_TYPE_SUCCESS:
      return {
        ...state,
        allBaseTypeMaterials: action.payload,
        loading: false,
      };

    case MATERIAL_DETAILS_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
