import React, { useEffect } from 'react';
import { MaterialDetailsView } from './MaterialDetailsView';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMaterial,
  updateMaterialInfo,
  updateRecipeVariantAction,
  updateMaterialSpecification,
  createRecipeVariantAction,
} from './MaterialDetailsActions';
import {
  MATERIAL_DETAILS_RESET,
  MATERIAL_SPECIFICATION_ADD,
  MATERIAL_SPECIFICATION_REMOVE,
} from './MaterialDetailsTypes';
import { useTranslation } from 'react-i18next';
import { transformRecipeVariantPercentageValue } from '../../utils/transform-helpers';

export default (props) => {
  const { t } = useTranslation(['materials', 'common', 'project', 'templates']);
  const dispatch = useDispatch();
  const materialDetails = useSelector((state) => state.materialDetails);
  const materials = useSelector((state) => state.material.data.docs);

  const addSpecification = (specification) => {
    dispatch({
      type: MATERIAL_SPECIFICATION_ADD,
      payload: specification,
    });
  };
  const removeSpecification = (testName) => {
    dispatch({
      type: MATERIAL_SPECIFICATION_REMOVE,
      payload: testName,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: MATERIAL_DETAILS_RESET,
      });
    };
  }, []);

  const saveSpecification = async (data) => {
    const response = await dispatch(
      updateMaterialSpecification(props.match.params.id, data),
    );
    return response;
  };

  const saveInformation = async (data) => {
    const response = await dispatch(
      updateMaterialInfo(props.match.params.id, data),
    );
    return response;
  };

  const saveRecipeVariantConfig = async (params) => {
    const data = {
      rows: params.rows.filter((recipeRow) => !recipeRow.deleted),
      name: params.name,
    };
    transformRecipeVariantPercentageValue(data);
    return await dispatch(
      createRecipeVariantAction(props.match.params.id, data),
    );
  };

  const editRecipeVariantConfig = async (recipeId, params) => {
    const data = {
      rows: params.rows.filter((recipeRow) => !recipeRow.deleted),
      name: params.name,
    };

    transformRecipeVariantPercentageValue(data);
    return await dispatch(
      updateRecipeVariantAction(props.match.params.id, data, recipeId),
    );
  };

  const goBackAction = () => {
    props.history.push('/dashboard/material');
  };

  const cancelSpecificationAction = () => {
    dispatch(fetchMaterial(props.match.params.id));
  };

  useEffect(() => {
    dispatch(fetchMaterial(props.match.params.id));
  }, [props.match.params.id]);

  return (
    <MaterialDetailsView
      data={materialDetails.data}
      addSpecification={addSpecification}
      removeSpecification={removeSpecification}
      saveSpecification={saveSpecification}
      cancelSpecificationAction={cancelSpecificationAction}
      saveInformation={saveInformation}
      recipeVariants
      saveRecipeVariantConfig={editRecipeVariantConfig}
      createRecipeVariantConfig={saveRecipeVariantConfig}
      goBackAction={goBackAction}
      materials={materials}
      t={t}
    />
  );
};
