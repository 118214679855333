import ReactSelect from 'react-select';
import React from 'react';

const customStyles = {
  option: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    borderBottom: '2px solid #e0e0e0',
    borderColor: 'white',
    boxShadow: '0',
    '&:hover': {
      borderBottom: '2px solid #e0e0e0',
    },
    padding: '0px 0px',
    margin: '0px 0px 0px 0px',
    width: '100px',
    borderRadius: 0,
    overflow: 'visible',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 0px',
  }),
};
export const RecipeVariantSelect = ({
  options,
  onChange,
  isDisabled,
  label,
  defaultValue,
}) => {
  const setSelectedLabel = (value, optionsData) => {
    var foundLabel;
    if (value) {
      foundLabel = optionsData.find((data) => {
        return data?.value?._id === value;
      });
    }
    return foundLabel;
  };

  return (
    <div>
      <ReactSelect
        options={options}
        onChange={(e) => {
          onChange(e?.value || null);
        }}
        isDisabled={isDisabled}
        styles={customStyles}
        placeholder={label}
        defaultValue={setSelectedLabel(defaultValue, options)}
      />
    </div>
  );
};
