import {
  PROJECT_RECIPE_RESET,
  PROJECT_RECIPE_ERROR,
  PROJECT_RECIPE_SUCCESS,
  PROJECT_RECIPE_START,
  RECIPE_VARIANT_ADD_ROW,
  RECIPE_VARIANT_ADD,
  RECIPE_VARIANT_EDIT_PERCENTAGE,
  RECIPE_VARIANT_EDIT_MATERIAL,
  RECIPE_VARIANTS_SAVE_SUCCESS,
  RECIPE_VARIANT_MARK_FINAL,
  PROJECT_RECIPE_FILTERED_VARIANTS_SUCCESS,
  PROJECT_RECIPE_FILTER_VARIANT,
} from './ProjectRecipeTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    recipeRowMap: {},
    recipe: {
      recipeVariants: [],
      filteredVariants: [],
      selectedSpecifications: [],
    },
  },
};



export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECT_RECIPE_START:
      return { ...state, loading: true, error: null };

    case PROJECT_RECIPE_SUCCESS:
      const {
        recipe: { recipeVariants = [] },
        referenceRecipe,
      } = action.payload;

      const recipeRowMap = {};

      if (referenceRecipe.length) {
        const finalVariant = referenceRecipe[0].recipeVariants?.find(
          (variant) => variant.final,
        );

        //If the recipe is created from reference recipe with final variant, put it into first place and make it editable
        if (!recipeVariants.length && finalVariant) {
          recipeVariants.push({
            ...finalVariant,
            new: true,
            final: false,
            _id: undefined,
          });
        }
      }

      recipeVariants[recipeVariants?.length - 1]?.rows?.map(
        (variantRow, rowIndex) => {
          const generatedId = Date.now() + Math.floor(Math.random() * 1000);
          recipeRowMap[generatedId] = {
            ...variantRow,
            generatedId,
            variants: [],
          };

          recipeVariants.map((variant, index) => {
            recipeRowMap[generatedId].variants[index] = {
              percentage: variant.rows[rowIndex]?.percentage || 0,
            };
          });
        },
      );
      
      recipeVariants.map(variant => {
        variant.isEditable = variant.results.some((result) => {
          return result.hasData === false;
        })
        return variant;
      })
      
     return {
        ...state,
        data: {
          ...action.payload,
          recipeRowMap: recipeRowMap,
        },
        loading: false,
      };


    case RECIPE_VARIANT_ADD_ROW:
      const tempRecipeRowMap = { ...state.data.recipeRowMap };

      const generatedId = Date.now() + Math.floor(Math.random() * 1000);
      tempRecipeRowMap[generatedId] = {
        generatedId,
        new: true,
        variants: [],
      };

      let tempRecipeVariants = state.data.recipe.recipeVariants;
      if (!tempRecipeVariants.length) {
        tempRecipeVariants = [
          {
            name: `Variant ${state.data.recipe.recipeVariants.length + 1}`,
            rows: [],
            new: true,
          },
        ];
      }

      tempRecipeVariants = tempRecipeVariants.map((variant, index) => {
        tempRecipeRowMap[generatedId].variants[index] = {
          percentage: 0,
        };
        return {
          ...variant,
          rows: [
            ...variant?.rows,
            {
              materialNumber: '',
              materialName: '',
              percentage: 0,
              position: variant.rows.length + 1,
            },
          ],
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipeRowMap: tempRecipeRowMap,
          recipe: {
            ...state.data.recipe,
            recipeVariants: tempRecipeVariants,
          },
        },
      };

    case RECIPE_VARIANT_ADD:
      if (state.data.recipe.recipeVariants.length === 0) {
        const generatedId = Date.now() + Math.floor(Math.random() * 1000);
        state.data.recipeRowMap[generatedId] = {
          percentage: 0,
          generatedId,
          variants: [],
          new: true,
        };
      }

      const newVariant = {
        rows: state.data.recipe.recipeVariants.length
          ? [...state.data.recipe.recipeVariants]?.pop()?.rows.map((row) => {
              delete row._id;
              return { ...row };
            })
          : [
              {
                materialNumber: '',
                materialName: '',
                percentage: 0,
                position: 1,
              },
            ],
        new: true,
        name: `Variant ${state.data.recipe.recipeVariants.length + 1}`,
      };

      Object.keys(state.data.recipeRowMap).forEach((key, stateRowIndex) => {
        state.data.recipeRowMap[key] = {
          ...state.data.recipeRowMap[key],
          variants: [
            ...state.data.recipeRowMap[key].variants,
            {
              percentage: newVariant.rows[stateRowIndex]?.percentage || 0,
              new: true,
            },
          ],
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            recipeVariants: [...state.data.recipe.recipeVariants, newVariant],
          },
        },
      };

    case RECIPE_VARIANT_EDIT_PERCENTAGE:
      const { rowIndex, variantIndex, value } = action.payload;

      state.data.recipe.recipeVariants[variantIndex].rows.map((row, index) => {
        if (index === rowIndex) {
          row.percentage = +value;
        }
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            recipeVariants: state.data.recipe.recipeVariants,
          },
        },
      };

    case RECIPE_VARIANT_EDIT_MATERIAL:
      const tempVariants = state.data.recipe.recipeVariants;

      tempVariants.map((variant) => {
        variant.rows[action.payload.rowIndex].materialNumber =
          action.payload.value;

        variant.rows[action.payload.rowIndex].materialName =
          action.payload.value;
        return { ...variant };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            recipeVariants: tempVariants,
          },
        },
      };

    case RECIPE_VARIANTS_SAVE_SUCCESS:
      return { ...state, loading: false, error: null };

    case RECIPE_VARIANT_MARK_FINAL:
      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            recipeVariants: state.data.recipe.recipeVariants.map((variant) => {
              if (variant._id === action.payload.variant) {
                variant.final = action.payload.final;
              }

              return variant;
            }),
          },
        },
        loading: false,
        error: null,
      };

    case PROJECT_RECIPE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROJECT_RECIPE_RESET:
      return INITIAL_STATE;

    case PROJECT_RECIPE_FILTER_VARIANT:
      const { variant, checked } = action.payload;

      let tempFilteredVariants = [
        ...(state.data.recipe.filteredVariants || []),
      ];

      if (checked && !tempFilteredVariants.includes(variant)) {
        tempFilteredVariants.push(variant);
      } else {
        tempFilteredVariants = tempFilteredVariants.filter(
          (filteredVariant) => filteredVariant !== variant,
        );
      }

      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            filteredVariants: tempFilteredVariants,
          },
        },
        loading: false,
      };

    case PROJECT_RECIPE_FILTERED_VARIANTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
            filteredVariants: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
