import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { PageTitle } from 'components';
import { RecipeVariantSelect } from './components/RecipeVariantSelect';
import { getRecipeVariantsForMaterial } from '../../repository/material';

export const RecipeVariantComparisonView = (props) => {
  const {
    goBackAction,
    getRecipeVariantsForMaterial,
    materialId,
    recipeVariantsData,
    t,
  } = props;

  const recipeVariantsRows = recipeVariantsData.flatMap((item) => item.rows);
  let recipePositionCounter: number = 1;
  const uniqueRecipeVariantsRows = [] as any;
  const materialNameSet = new Set() as any;

  for (const recipeVariantRow of recipeVariantsRows) {
    if (!materialNameSet.has(recipeVariantRow?.materialName)) {
      materialNameSet.add(recipeVariantRow?.materialName);
      uniqueRecipeVariantsRows?.push(recipeVariantRow);
    }
  }

  const findPercentageInRecipe = (materialId, recipe) => {
    return (
      recipe
        .find(
          (recipeItem) =>
            `${recipeItem.materialName}_${recipeItem.materialNumber}` ===
            materialId,
        )
        ?.percentage?.toFixed(2) || '0.00'
    );
  };

  const [firstRecipe, setFirstRecipe] = useState(recipeVariantsData[0] || null);
  const [secondRecipe, setSecondRecipe] = useState(
    recipeVariantsData[1] || null,
  );
  const [thirdRecipe, setThirdRecipe] = useState(recipeVariantsData[2] || null);
  const [fourthRecipe, setFourthRecipe] = useState(
    recipeVariantsData[3] || null,
  );
  const [fifthRecipe, setFifthRecipe] = useState(recipeVariantsData[4] || null);
  const [tableItemsList, setTableItemsList] = useState([
    firstRecipe,
    secondRecipe,
    thirdRecipe,
    fourthRecipe,
    fifthRecipe,
  ]);

  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    getRecipeVariantsForMaterial(materialId);
  }, [materialId]);

  useEffect(() => {
    setFirstRecipe(recipeVariantsData[0]);
    setSecondRecipe(recipeVariantsData[1]);
    setThirdRecipe(recipeVariantsData[2]);
    setFourthRecipe(recipeVariantsData[3]);
    setFifthRecipe(recipeVariantsData[4]);
    setTableItemsList([
      recipeVariantsData[0] || null,
      recipeVariantsData[1] || null,
      recipeVariantsData[2] || null,
      recipeVariantsData[3] || null,
      recipeVariantsData[4] || null,
    ]);

    setOptions(
      recipeVariantsData.map((item) => ({
        label: item.name,
        value: item,
      })),
    );
  }, [recipeVariantsData]);

  return (
    <div>
      <PageTitle title={recipeVariantsData[0]?.material?.name}>
        <Button color="primary" onClick={() => goBackAction()}>
          <Typography variant="body2">{t('goBack')}</Typography>
        </Button>
      </PageTitle>
      <Grid container className={'box-holder'} style={{ overflowY: 'auto' }}>
        <Grid container item justify={'space-between'} alignItems={'center'}>
          <Grid item container xs={4}>
            <Typography variant={'h3'}>
              {t('recipeVariantComparison')}
            </Typography>
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ overflow: 'visible' }}>
          <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  {firstRecipe && (
                    <RecipeVariantSelect
                      options={options}
                      onChange={(value) => setFirstRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 1'}
                      defaultValue={firstRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {secondRecipe && (
                    <RecipeVariantSelect
                      options={options}
                      onChange={(value) => setSecondRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 2'}
                      defaultValue={secondRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {thirdRecipe && (
                    <RecipeVariantSelect
                      options={options}
                      onChange={(value) => setThirdRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 3'}
                      defaultValue={thirdRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {fourthRecipe && (
                    <RecipeVariantSelect
                      options={options}
                      onChange={(value) => setFourthRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 4'}
                      defaultValue={fourthRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {fifthRecipe && (
                    <RecipeVariantSelect
                      options={options}
                      onChange={(value) => setFifthRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 5'}
                      defaultValue={fifthRecipe._id}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('position')}</TableCell>
                <TableCell>{t('materialNo')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                {firstRecipe && <TableCell>{t('percentage')}</TableCell>}
                {secondRecipe && <TableCell>{t('percentage')}</TableCell>}
                {thirdRecipe && <TableCell>{t('percentage')}</TableCell>}
                {fourthRecipe && <TableCell>{t('percentage')}</TableCell>}
                {fifthRecipe && <TableCell>{t('percentage')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(uniqueRecipeVariantsRows || []).map((item, index) => {
                return (
                  <TableRow>
                    <TableCell>{recipePositionCounter++}</TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.materialNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.materialName}
                    </TableCell>
                    {firstRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          firstRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {secondRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          secondRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {thirdRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          thirdRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {fourthRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          fourthRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {fifthRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          fifthRecipe.rows,
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};
