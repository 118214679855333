import {
  MATERIAL_RECIPE_VARIANT_COMPARISON_RESET,
  MATERIAL_RECIPE_VARIANT_COMPARISON_ERROR,
  MATERIAL_RECIPE_VARIANT_COMPARISON_SUCCESS,
  MATERIAL_RECIPE_VARIANT_COMPARISON_START,
} from './RecipeVariantComparisonTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case MATERIAL_RECIPE_VARIANT_COMPARISON_START:
      return { ...state, loading: true, error: null };

    case MATERIAL_RECIPE_VARIANT_COMPARISON_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case MATERIAL_RECIPE_VARIANT_COMPARISON_ERROR:
      return { ...state, loading: false, error: action.payload };

    case MATERIAL_RECIPE_VARIANT_COMPARISON_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
