import API from 'services/api';
import { MaterialType } from '../containers/Material/MaterialView';

export const createMaterial = async (data: any) => {
  return await API.post('/material', data);
};

export const editMaterial = async (id: number, data: any) => {
  return await API.put(`/material/${id}`, data);
};

export const getMaterial = async (id) => {
  return await API.get(`/material/${id}`);
};

export const removeMaterial = async (id) => {
  return await API.delete(`/material/${id}`);
};

export const getMaterials = async (props) => {
  const { limit = 10, page = 0, sort, name, filter = '' } = props;
  return await API.get('/material', {
    params: { limit, page, sort, name, filter },
  });
};

export const getBaseMaterials = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/material', {
    params: { limit, page, sort, name, type: MaterialType.BASE, filter },
  });
};

export const getRecipes = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/material', {
    params: { limit, page, sort, name, type: MaterialType.RECIPE, filter },
  });
};

export const getRecipeVariantsForMaterial = async (id) => {
  return await API.get(`/material/${id}/recipe-variants`);
};

export const editMaterialSpecification = async (id, data) => {
  return await API.put(`/material/${id}/specification`, data);
};

export const editRecipeVariant = async (materialId, data, recipeVariantId) => {
  return await API.put(
    `/material/${materialId}/recipe-variant/${recipeVariantId}`,
    data,
  );
};

export const createRecipeVariant = async (materialId, data) => {
  return await API.put(`/material/${materialId}/recipe-variant`, data);
};

export const getMaterialSpecifications = async (materialId) => {
  return await API.get(`/material/${materialId}/specification`);
};
