import React from 'react';
import './MaterialDetails.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import { SpecificationSection } from './components/SpecificationSection';
import { InformationSection } from './components/InformationSection';
import { PageTitle } from '../../components';
import { RecipeVariantSection } from './components/RecipeVariantSection';

export const MaterialDetailsView = (props) => {
  const {
    data,
    addSpecification,
    removeSpecification,
    saveSpecification,
    cancelSpecificationAction,
    saveInformation,
    goBackAction,
    saveRecipeVariantConfig,
    createRecipeVariantConfig,
    materials,
    t,
  } = props;

  return (
    <div className="page-material-details">
      <Grid item container justify={'space-between'}>
        <PageTitle title={data.name}>
          <Button color="primary" onClick={() => goBackAction()}>
            <Typography variant="body2">{t('goBack')}</Typography>
          </Button>
        </PageTitle>
      </Grid>

      <Grid container item spacing={3}>
        {data.type === 'Recipe' && (
          <Grid item container xs={6}>
            <RecipeVariantSection
              material={data}
              materials={materials}
              editRecipeVariantConfig={saveRecipeVariantConfig}
              createRecipeVariantConfig={createRecipeVariantConfig}
              t={t}
            />
          </Grid>
        )}
        <Grid item container xs={data.type == 'Recipe' ? 6 : 12} spacing={1}>
          <SpecificationSection
            addRow={addSpecification}
            deleteRow={removeSpecification}
            saveSpecification={saveSpecification}
            rows={data.specificationRowMap}
            cancel={cancelSpecificationAction}
            isRecipe={data.type === 'Recipe'}
            t={t}
            material={data}
          />
          <InformationSection
            material={data}
            saveInformation={saveInformation}
            isRecipe={data.type === 'Recipe'}
            t={t}
          />
        </Grid>
      </Grid>
    </div>
  );
};
